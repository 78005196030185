.App {
  text-align: left;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px;
  /* margin-left : 2em; */
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
  margin-left: 2em;
  padding : 0 1em;
}

.App-header #name {
  height : 2em;
  margin-top: 1.5em;
}

.App-link {
  color: #61dafb;
}

nav {
  position: absolute;
  top: 20px;
  right: 4em;
}

ul {
  display: flex;
  gap: 20px;
  list-style-type: none;
  padding: 0;
}

ul li {
  padding : 1em;
}

.main-content {
  width: 100%;
  height: calc(100vh - 80px);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
